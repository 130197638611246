import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { HomeOutlined, ExitToAppOutlined, Menu as MenuIcon, InsertChartOutlined, AddModeratorOutlined, ListAltOutlined, PersonAddAltOutlined, ContactMailOutlined, AddCommentOutlined, TuneOutlined, StraightenOutlined, SettingsOutlined, DataSaverOffOutlined, ChatOutlined, AccountCircle as AccountCircleIcon, TopicOutlined, PsychologyOutlined, EditNoteOutlined, CommentOutlined, AccessTimeOutlined, PriorityHighOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../features/authSlice';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import trackEvent from '../utils/trackEvent';
import placeholderImg from '../assets/images/user-placeholder.png';
import { Avatar } from '@mui/material';

const Container = styled.div``;

const Icon = styled(({ active, ...rest }) => <div {...rest} />)`
  color: ${({ active }) => (active ? '#888' : '#3498db')};
  height: 25px;
  width: 25px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 1000;
  }
`;

const MenuMobileWrapper = styled.div`
  flex-direction: column;
  position: absolute;
  width: 250px;
  height: 100vh;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background: #EAEDED;
  padding: 5px 0;
  border-top: 4px solid #3498d8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

  @media (min-width: 769px) {
    display: none;
  }
`;

const MenuItem = styled(NavLink)`
  display: flex;
  color: #3498db;
  text-decoration: none;
  padding: 6px 15px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
`;

const MenuIconWrapper = styled.div`
  color: #3498db;
  font-size: 28px;
  cursor: pointer;
  margin-right: 25px;
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    z-index: 10;
    top: 5px;
    left: 5px;
  }
`;

const MenuListWrapper = styled.div`
  margin-top: 30px;
`;

const DesktopMenu = styled.div`
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
  justify-content: left;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px 0px #ddd;

  @media (max-width: 768px) {
    display: none;
  }
`;

const UserWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 3px;
`

const AvatarImageMobile = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 5px;
  right: 10px;

  @media (min-width: 769px) {
    display: none; /* Oculta el avatar en pantallas grandes */
  }
`;

const UserText = styled.span`
  font-size: 14px;
  margin-left: 10px;
  padding-right: 25px;
`;

const LogoutIcon = styled(ExitToAppOutlined)`
  font-size: 18px;
  margin-bottom: 5px;
  margin-right: 5px;
`;

const DropdownMenu = styled.div`
  width: 130px;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  border-top: 4px solid #3498d8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

const DropdownMenuNew = styled.div`
  width: 280px;
  position: absolute;
  top: 45px;
  left: 0;
  background-color: #fff;
  border-top: 4px solid #3498d8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

const DropdownMenuItem = styled(MenuItem)`
  border-bottom: none;
`;

const LogoutText = styled.div`
  margin-top: 3px;
  margin-right: 20px;
  margin-left: 5px;
`;

const GroupedMenuItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const MyAccountText = styled.div`
  margin-top: 3px;
  margin-right: 10px;
  margin-left: 5px;
`;

const IconWrapper = styled.div`
  position: relative;
  font-size: 18px;
`;

const Menu = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNewMenuOpen, setIsNewMenuOpen] = useState(false);

  const handleLogout = async (e) => {
    try {
      e.preventDefault();
      await trackEvent('logout', 'The user logged out', user.email);
      await axios.get(`${apiUrl}/auth/logout`);
      dispatch(setLogout());
    } catch (error) {
      console.error('Error registering logout event:', error);
    }
  };

  const handleTracking = (path) => {
    trackEvent('navigation', path, user.email );
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleNewMenu = () => {
    setIsNewMenuOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <Container>
      <MenuIconWrapper onClick={toggleMobileMenu}>
        <MenuIcon />
      </MenuIconWrapper>
      <AvatarImageMobile src={user?.picture || placeholderImg} />
      {isMobileMenuOpen && (
        <MenuMobileWrapper>
          <MenuListWrapper>
            {['admin', 'collaborator'].includes(user?.role) && (
              <>
                <MenuItem to="/" onClick={() => handleTracking('/home')}>Home</MenuItem>
                <MenuItem to="/recent-toxic-content-dashboard" onClick={() => handleTracking('/recent-toxic-content-dashboard')}>Recent Toxic Content Dashboard</MenuItem>
                <MenuItem to="/add-tweet" onClick={() => handleTracking('/add-tweet')}>Add Tweet</MenuItem>
                <MenuItem to="/boosting-counterspeaking" onClick={() => handleTracking('/boosting-counterspeaking')}>Boosting Counterspeaking</MenuItem>
                <MenuItem to="/my-impact" onClick={() => handleTracking('/my-impact')}>My Impact</MenuItem>
                <MenuItem to="/my-account" onClick={() => handleTracking('/my-account')}>My Account</MenuItem>
              </>
            )}
            {user?.role === 'admin' && (
              <>
                <MenuItem to="/admin">Admin Dashboard</MenuItem>
                <MenuItem to="/analytics-users">Users Analytics</MenuItem>
                <MenuItem to="/analytics-posts">Posts Analytics</MenuItem>
                <MenuItem to="/user-screen">Users Screen</MenuItem>
                <MenuItem to="/watchlist-profiles">Accounts to Watch</MenuItem>
                <MenuItem to="/responses-strategies">Response Strategies</MenuItem>
                <MenuItem to="/tpc-categories">Toxicity Types</MenuItem>
                <MenuItem to="/measure-toxicity">Measure Toxicity</MenuItem>
                <MenuItem to="/interventions">Interventions</MenuItem>
                <MenuItem to="/topics">Topics</MenuItem>
                <MenuItem to="/admin/settings">Admin Settings</MenuItem>
              </>
            )}
            <DropdownMenuItem to="/" onClick={(e) => { handleLogout(e); setIsMobileMenuOpen(false); }}>
              <LogoutIcon /> Logout
            </DropdownMenuItem>
          </MenuListWrapper>
        </MenuMobileWrapper>
      )}
      <DesktopMenu>
        {['admin', 'collaborator'].includes(user?.role) && (
          <>
            <NavLink to="/" onClick={() => handleTracking('/home')}>
              <Icon data-tooltip="Home" active={window.location.pathname === '/'}><HomeOutlined /></Icon>
            </NavLink>
            <NavLink to="/recent-toxic-content-dashboard" onClick={() => handleTracking('/recent-toxic-content-dashboard')}>
              <Icon data-tooltip="Recent Toxic Content" active={window.location.pathname === '/recent-toxic-content-dashboard'}><AccessTimeOutlined /></Icon>
            </NavLink>
            <NavLink to="/add-tweet" onClick={() => handleTracking('/add-tweet')}>
              <Icon data-tooltip="Add Tweet" active={window.location.pathname === '/add-tweet'}><AddCommentOutlined /></Icon>
            </NavLink>
            <NavLink to="/boosting-counterspeaking" onClick={() => handleTracking('/boosting-counterspeaking')}>
              <Icon data-tooltip="Boosting Counterspeaking" active={window.location.pathname === '/boosting-counterspeaking'}><CommentOutlined /></Icon>
            </NavLink>
            <NavLink to="/my-impact" onClick={() => handleTracking('/my-impact')}>
              <Icon data-tooltip="My Impact" active={window.location.pathname === '/my-impact'}><PriorityHighOutlined/></Icon>
            </NavLink>
          </>
        )}
        {user?.role === 'admin' && (
          <>
            <NavLink to="/admin" >
              <Icon data-tooltip="Admin Dashboard" active={window.location.pathname === '/admin'}><InsertChartOutlined /></Icon>
            </NavLink>
            <NavLink to="/analytics-users" >
              <Icon data-tooltip="Users Analytics" active={window.location.pathname === '/analytics-users'}><ContactMailOutlined /></Icon>
            </NavLink>
            <NavLink to="/analytics-posts" >
              <Icon data-tooltip="Posts Analytics" active={window.location.pathname === '/analytics-posts'}><ListAltOutlined /></Icon>
            </NavLink>
            <NavLink to="/user-screen" >
              <Icon data-tooltip="Users Screen" active={window.location.pathname === '/user-screen'}><PersonAddAltOutlined /></Icon>
            </NavLink>
            <NavLink to="/watchlist-profiles" >
              <Icon data-tooltip="Accounts to Watch" active={window.location.pathname === '/watchlist-profiles'}><AddModeratorOutlined /></Icon>
            </NavLink>
            <NavLink to="/measure-toxicity" >
              <Icon data-tooltip="Measure Toxicity" active={window.location.pathname === '/measure-toxicity'}><StraightenOutlined /></Icon>
            </NavLink>
            <NavLink to="/interventions" >
              <Icon data-tooltip="Interventions" active={window.location.pathname === '/interventions'}><DataSaverOffOutlined /></Icon>
            </NavLink>
            <NavLink to="/admin/settings" >
              <Icon data-tooltip="Admin Settings" active={window.location.pathname === '/admin/settings'}><SettingsOutlined /></Icon>
            </NavLink>
            <IconWrapper>
              <Icon data-tooltip="AI Configs" onClick={toggleNewMenu} active={isNewMenuOpen}><PsychologyOutlined /></Icon>
              {isNewMenuOpen && (
                <DropdownMenuNew>
                  <GroupedMenuItem>
                    <MenuItem to="/responses-strategies">Response Strategies <ChatOutlined style={{ marginBottom: '5px', marginLeft: '46px' }} /></MenuItem>
                    <MenuItem to="/tpc-categories">Toxicity Types<TuneOutlined style={{ marginBottom: '5px', marginLeft: '100px' }} /></MenuItem>
                    <MenuItem to="/topics">Topics<TopicOutlined style={{ marginBottom: '5px', marginLeft: '160px' }} /></MenuItem>
                    <MenuItem to="/writing-styles">Writing Styles<EditNoteOutlined style={{ marginLeft: '100px' }} /></MenuItem>
                  </GroupedMenuItem>
                </DropdownMenuNew>
              )}
            </IconWrapper>
          </>
        )}
        <UserWrapper>
          <Avatar onClick={toggleDropdown} src={user?.picture || placeholderImg} style={{ cursor:'pointer' }}/>
          <UserText>{user?.name?.givenName}</UserText>
          {isDropdownOpen && (
            <DropdownMenu>
              <DropdownMenuItem to="/my-account" onClick={() => {toggleDropdown(); handleTracking('/my-account')}}>
                <MyAccountText>Account</MyAccountText><AccountCircleIcon />
              </DropdownMenuItem>
              <DropdownMenuItem to="/" onClick={(e) => { handleLogout(e); toggleDropdown(); }}>
                <LogoutText>Logout</LogoutText><LogoutIcon />
              </DropdownMenuItem>
            </DropdownMenu>
          )}
        </UserWrapper>
      </DesktopMenu>
    </Container>
  );
};

export default Menu;