import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
import { CheckCircle } from '@mui/icons-material';
import { Typography, Button } from '@mui/material';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Spinner from '../components/Spinner';
import InterventionsComponent from '../components/InterventionsComponent';
import useVerifyTwitterUrl from '../utils/useVerifyTwitterUrl';
import { apiUrl } from '../utils/common';
import trackEvent from '../utils/trackEvent';

const HeaderContent = styled.div`
    margin-top: 50px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 60px; 
    width: 100%;
    @media (max-width: 768px) {
       flex-direction: column;
       align-items: center;
    }
`;

const Form = styled.form`
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    flex: 1;
`;

const Title = styled.h2`
    color: #3498db;
`;

const Input = styled.input`
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 0.5px solid #ccc;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    margin-top: 5px;
    font-weight: lighter;
`;

const SuccessMessage = styled.div`
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 20px 0;
`;
const EditableText = styled.textarea`
    box-sizing: border-box;
    font-size: 14px;
    line-height: 21px;
    min-height: 100px;
    padding: 10px;
    resize: none;
    width: 100%;
`
const ButtonsWrapper = styled.div`
    display: flex;
    margin: 15px 0 15px 0;
    text-align: center;
    justify-content: space-between;
`

const BoostingCounterspeaking = () => {
    const user = useSelector((state) => state.auth.user);
    const { verifyUrl, getTweetIdFromTwitterUrl } = useVerifyTwitterUrl();
    const [tweetURL, setTweetURL] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageModal, setMessageModal] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [editText, setEditText] = useState('');
    const [twitterPostId, setTwitterPostId] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [tweetUrl, setTweetUrl] = useState('');
    const [interventionsList, setInterventionsList] = useState([]);
    const [boostedList, setBoostedList] = useState([]);
    const [userTwitterId, setUserTwitterId] = useState("");
    const [repliesList, setRepliesList] = useState([]);
    const [loadedTweet, setLoadedTweet] = useState([]);
    const [replyToShow, setReplyToShow] = useState([]);
    const [textToSuggest, setTextToSuggest] = useState("");
    const [isReplyLiked, setIsReplyLiked] = useState(false);

    const isMobile = window.innerWidth <= 768;
    const userIdRef = useRef(user._id);
    const mainTwitterAccountRef = useRef(user.mainTwitterAccount);

    const fetchData = async () => {
        setLoading(true);
        let userId =  userIdRef.current;
        let mainTwitterAccount = mainTwitterAccountRef.current;
        try {
            const response = await axios.get(`${apiUrl}/boostCounterSpeak/getInterventionsList`, { params: { userId, mainTwitterAccount} });
            setInterventionsList(response.data?.interventions || []);
            setBoostedList(response.data?.boostedList || []);
            setUserTwitterId(response.data?.userTwitterId || "");
            setRepliesList(response.data.repliesToUser || [])
        } catch (error) {
            console.error('Error fetching interventions:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);
    
    const handleBoostedListUpdate = (updatedList) => {
        setBoostedList(updatedList);
    };

    const handleURLChange = (event) => {
        setTweetURL(event.target.value);
    };

    const handleLoadTweet = async (event) => {
        event.preventDefault();

        if (!verifyUrl(tweetURL)) {
            setErrorMessage('Please enter a valid Twitter or X URL.');
        } else {
            setLoading(true);
            setErrorMessage('');
            const tweetId = getTweetIdFromTwitterUrl(tweetURL);  // Retorna el ID del post
            setTwitterPostId(tweetId);
            try {
                const response = await axios.get(`${apiUrl}/boostCounterSpeak/getTweetText`, { params: { tweetId } });
                setLoadedTweet(response.data);
                setTextToSuggest(response.data[0]?.text?.replace(/^(@\w+\s)+/, ''))
                setOpenModal(true);

            } catch (error) {
                setMessageModal('Error loading Tweet.');
                setShowModal(true);
                console.error('Error during the request:', error);
            }
            setLoading(false);
        }
    };    
    
    const handleSuggestResponse = async (event) => {
        event.preventDefault();
        setLoading(true);
            try {
                const response = await axios.post(`${apiUrl}/boostCounterSpeak`, { text: textToSuggest });
                setEditText(response.data[0]);

            } catch (error) {
                setMessageModal('Error loading Tweet.');
                setShowModal(true);
                console.error('Error during the request:', error);
            }
            setLoading(false);
    };

    const handleCloseModal = () => {
        setMessageModal('');
        setTweetURL('');
        setOpenModal(false);
        setLoadedTweet([]);
        setReplyToShow([]);
        setTextToSuggest('');
        setEditText('');
        setTwitterPostId(null);
        setIsSuccess(false);
        setShowModal(false);
        setIsReplyLiked(false)
    };

    const closeModalError = () => {
        if (messageModal === 'Error posting Tweet.') {
            setOpenModal(true);
            setShowModal(false);
        } else {
            handleCloseModal(); 
        }
    };

    const handleEditTextChange = (e) => { setEditText(e.target.value) };

    const handleSubmitTweet = async (like) => {
        try {
            const response = await axios.post(`${apiUrl}/boostCounterSpeak/replyTweet`, { userMainTwitterAccount: user.mainTwitterAccount, twitterPostId, userTwitterId, tweetText: editText, like, isReplyLiked });
            if (response) {
                const tweetUrlId = response.data.responseData?.data?.id;
                setBoostedList(response.data?.boostedList || []);
                if (tweetUrlId) {
                    setIsSuccess(true);
                    setTweetUrl(`https://x.com/${user.mainTwitterAccount}/status/${tweetUrlId}`);
                    }
                else {
                    setMessageModal('Error posting Tweet.');
                    setShowModal(true);
                    setOpenModal(false)
                }
            }
           
        } catch (error) {
            console.error('Error posting tweet:', error);
        }
    };

    const handleTracking = (path) => {
        trackEvent('navigation', path, user.email);
    };

    const handleReply = async (intervention, isReplyLiked, reply) => {
        try {
            setLoadedTweet([intervention]);
            setReplyToShow([reply]);
            setTwitterPostId(reply? reply.id : getTweetIdFromTwitterUrl(intervention.responseUrl))
            setTextToSuggest(reply? reply.text?.replace(/^(@\w+\s)+/, '') : intervention.interventionText?.replace(/^(@\w+\s)+/, ''));
            setOpenModal(true);
            setIsReplyLiked(isReplyLiked);
            
        } catch (error) {
            console.error('Error Replying tweet:', error);
        }
    };

    const errorLiking = () => {
        setMessageModal('Error to like/unlike the Tweet. Please try again in a few minutes.');
        setShowModal(true);
    };

    return (
        <>
            <Menu />
            <HeaderContent>
                <Header title="Support an anti-toxic tweet" />
            </HeaderContent>
            <Container>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>
                            <Form>
                                <InterventionsComponent
                                    interventionsList={interventionsList.userInterventions}
                                    userOption="me"
                                    userMainTwitterAccount={user.mainTwitterAccount}
                                    boostedListProp={boostedList}
                                    boostedListUpdate={handleBoostedListUpdate}
                                    userTwitterId={userTwitterId}
                                    repliesList={repliesList}
                                    handleReply={handleReply}
                                    errorLiking={errorLiking}
                                />
                            </Form>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '50%' }}>

                            <Form>
                                <InterventionsComponent
                                    interventionsList={interventionsList.allUsersInterventions}
                                    userOption="all"
                                    userMainTwitterAccount={user.mainTwitterAccount}
                                    boostedListProp={boostedList}
                                    boostedListUpdate={handleBoostedListUpdate}
                                    userTwitterId={userTwitterId}
                                    handleReply={handleReply}
                                    errorLiking={errorLiking}
                                />
                            </Form>

                            <Form onSubmit={handleLoadTweet} style={{ padding: '0px 10px 10px 10px' }}>
                                <Title>Support an external anti-toxic tweet</Title>
                                <Typography style={{ fontSize: '12px', margin: '-10px 0px 15px 0px' }}>
                                    If you found a tweet on x (outside of our own SMD team) that you'd like to bring into the platform, enter the URL below and click the button to support that counterspeech
                                </Typography>
                                <Input
                                    type="text"
                                    placeholder="Enter tweet URL"
                                    value={tweetURL}
                                    onChange={handleURLChange}
                                />
                                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                                <Button type="submit" variant='contained' style={{ alignSelf: 'flex-end' }}>get supportive replies</Button>
                            </Form>
                        </div>
                    </>
                )}
                <Modal
                    openModal={showModal}
                >
                    <strong>{messageModal}</strong>
                    <br></br>
                    <Button onClick={closeModalError}>CLOSE</Button>
                </Modal>
            </Container>

            <Modal
                openModal={openModal}
                showActions={false}
                buttonAction={'Confirm'}
                maxwidth={'600px'}
            >
                {isSuccess ? (
                    <>
                        <SuccessMessage>
                            <CheckCircle color='success' fontSize='large' />
                            <br />
                            Success! Your answer has been posted.
                            {tweetUrl && <a href={tweetUrl} target="_blank" rel="noopener noreferrer">See your Tweet</a>}
                            <br />
                            <Link to={'/recent-toxic-content-dashboard'} onClick={() => handleTracking('/recent-toxic-content-dashboard')}>Go to Recent Content</Link>
                            <br />
                            <Button size="small" variant='outlined' onClick={handleCloseModal}>Cancel</Button>
                        </SuccessMessage>
                    </>
                ) : (

                    <>
                        <InterventionsComponent
                            interventionsList={loadedTweet}
                            userMainTwitterAccount={user.mainTwitterAccount}
                            boostedListProp={boostedList}
                            boostedListUpdate={handleBoostedListUpdate}
                            userTwitterId={userTwitterId}
                            repliesList={replyToShow}
                        />
                        <EditableText onChange={handleEditTextChange} value={editText} />
                        {loading ? (
                            <Spinner />
                        ) : (
                            <ButtonsWrapper>
                                <Button size="small" variant='outlined' onClick={handleCloseModal}>Cancel</Button>
                                <Button size="small" disabled={loading} onClick={handleSuggestResponse}>Suggest a potential Response</Button>
                                <Button size="small" disabled={editText === '' || loading} onClick={() => handleSubmitTweet(false)}>Reply</Button>
                                <Button size="small" variant='contained' disabled={editText === '' || loading} onClick={() => handleSubmitTweet(true)}>Like & Reply</Button>
                            </ButtonsWrapper>
                        )}
                    </>

                )}
            </Modal>
        </>
    );
};

export default BoostingCounterspeaking;