import React from 'react';
import styled from 'styled-components';
import Header from './Header';

const ChartContainer = styled(({ fromMyImpact, ...rest }) => <div {...rest} />)`
    box-sizing: border-box;
    ${({ fromMyImpact }) => fromMyImpact && 'min-height: 450px;'}
    margin-top: 25px;
    padding: 0 15px;

    @media (max-width: 768px) {
        margin-top: ${({ fromMyImpact }) => (fromMyImpact ? '0px' : '25px')};
        margin-right: ${({ fromMyImpact }) => (fromMyImpact ? '5px' : '0px')};
        margin-left: ${({ fromMyImpact }) => (fromMyImpact ? '-15px' : '0px')};
    }
`;


const NameItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin: 5px 0;
    background: #f9f9f9;
    border-radius: 5px;
    transition: background 0.3s;
`;

const Index = styled.span`
    color: ${({ color }) => color};
    font-size: 1.5rem;
    font-weight: bold;
    
`;

const PositionIndex = styled(({ color, ...rest }) => <span {...rest} />)`
    color: ${({ color }) => color};
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
    vertical-align: super;
`;

const Name = styled(({ first, ...rest }) => <span {...rest} />)`
    font-size: 1rem;
    font-weight: ${({ first }) => (first ? 'bold' : 'normal')};
    font-style: italic;
`;

const NoDataMessage = styled.div`
    align-items: center;
    display: flex;
    height: 450px;
    justify-content: center;
    width: 100%;
`

//COMPONENTE PARA RENDERIZAR LA LISTA DE LIDER BOARD
const LeaderBoard = ({ranking, fromMyImpact = false}) => {
   const arrayRanking = ranking;

   return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: fromMyImpact? '70px' : '0px'}}>
            <Header titleFontSize={21} title={'Leader Board'} />
            <ChartContainer fromMyImpact={fromMyImpact}>
                {arrayRanking && arrayRanking.length ? arrayRanking.map((item, index) => (
                    <NameItem key={index}>
                        <Index color={index === 0 ? 'red' : '#3498db'}>
                            {index + 1}
                        </Index>
                        <PositionIndex color={index === 0 ? 'red' : ' #3498db'}>
                            {index === 0 ? 'st' : index === 1 ? 'nd' : index === 2 ? 'rd' : `th`}
                        </PositionIndex>
                        <Name first={index === 0}> @{item?.userData?.name}</Name>
                    </NameItem>
                )) : (
                    <NoDataMessage>No data to display.</NoDataMessage>
                )}
            </ChartContainer>
        </div>
    );
};

export default LeaderBoard;
