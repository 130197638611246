import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Menu from '../components/Menu';
import { CheckBox } from '@mui/icons-material';
import { Typography } from '@mui/material';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import LeaderBoard from '../components/LeaderBoard';
import ImpactChart from '../components/ImpactChart';
import Spinner from '../components/Spinner';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
    }
`;

const HeaderContent = styled.div`
    margin-top: 30px;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Wrapper = styled.div`
    background: #E0E0E0;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    margin: 20px;
    padding: 0px 12px 18px 12px;
    font-size: 14px;
`;

const BestInterventionWrapper = styled(({ isMobile, showLeaderBoard, ...rest }) => <Wrapper {...rest} />)`
    width: ${({ isMobile }) => (isMobile ? '95%' : '100%')};
    padding-left: 25px;
    padding-right: 25px;
    margin: ${({ isMobile, showLeaderBoard }) => (isMobile || showLeaderBoard ? '0 0 10px 0' : '75px 20px 20px 20px')};
`;

const Title = styled.h2`
    font-size: 18px;
    color: #3498db;
    padding-top:15px;
`;

const EngagementText = styled.div`
    flex: 3;
    text-align: right;
    color: #777;
`;

const MyImpact = () => {
    const user = useSelector((state) => state.auth.user)
    const [interventionsData, setInterventionsData] = useState({});
    const [engagement, setEngagement] = useState({});
    const [rankingInfo, setRankingInfo] = useState({});
    const [rankingUsers, setRankingUsers] = useState({});
    const [bestInterventions, setBestInterventions] = useState({});
    const [userIdInterventions, setUserIdInterventions] = useState([]);
    const [allUsersInterventions, setAllUsersInterventions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const userId = user._id;
                const response = await axios.get(`${apiUrl}/metrics/getImpact`, {
                    params: { userId }
                });
                setIsLoading(false);
                if (response) {
                    const data = response.data;
                    setInterventionsData(data.interventionsData ?? {});
                    setEngagement(data.engagement ?? {});
                    setRankingInfo(data.ranking ?? {});
                    setRankingUsers(data.ranking?.allTimeRanking ?? {});
                    setBestInterventions(data.maxEngagementInterventions ?? {});
                    setUserIdInterventions(data.interventionsList?.userAllTime ?? []);
                    setAllUsersInterventions(data.interventionsList?.lastWeek ?? []);
                }
            } catch (error) {
                console.error("Error fetching metrics:", error);
            }
        };
        fetchData();
    }, []);

    const BestInterventionComponent = ({ bestIntervention, showLeaderBoard = false }) => {
        const interventionText = bestIntervention?.interventionText || "";
        const words = interventionText.split(' ');
        const textWithoutFirstWord = words[0].startsWith('@') ? words.slice(1).join(' ') : interventionText;
        const createdAt = bestIntervention?.createdAt ? new Date(bestIntervention.createdAt) : null;
        const formattedDate = createdAt ? createdAt.toLocaleDateString('en-US') : 'N/A';
        const updatedAt = bestIntervention?.monitorData?.updatedAt ? new Date(bestIntervention.monitorData.updatedAt) : null;
        const metricsDate = updatedAt ? format(updatedAt, "MM/dd/yyyy 'at' hh:mm a") : 'N/A';

        return (
            <BestInterventionWrapper isMobile={isMobile} showLeaderBoard={showLeaderBoard}>
                <div style={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
                    <CheckBox style={{ color: '#32CD32', marginRight: '5px' }}></CheckBox>
                    <Title style={{ paddingTop: '0', marginTop: '2px', color: '#000', marginBottom: '-10px' }}>
                        Impact Highlight:
                    </Title>
                </div>

                <div>
                    <p>{textWithoutFirstWord ? `${textWithoutFirstWord}` : ""}</p>
                    <EngagementText style={{ textAlign: 'left' }}>
                        {bestIntervention?.userData ? `${bestIntervention?.userData?.mainTwitterAccount} in reply to ${bestIntervention?.toxicUserDetails?.username} on ${formattedDate}` : ""}
                    </EngagementText>
                    <EngagementText style={{ textAlign: 'left', marginTop: '5px', marginBottom: '0px' }}>
                        {bestIntervention?.userData && ` ${formatMetrics(bestIntervention?.monitorData?.metrics?.intervention)}`}
                        {bestIntervention?.engagement !== undefined ? `, engagement score: ${bestIntervention?.engagement} as of ${metricsDate} ` : ''}
                    </EngagementText>
                </div>

                {!bestIntervention &&<div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "70px" }}>
                    <Typography variant="h7" color="textSecondary">
                        No interventions available
                    </Typography>
                </div>}
            </BestInterventionWrapper>
        );
    };

    const formatMetrics = (metrics) => {
        const metricsArray = [];
        if (metrics?.impressions) metricsArray.push(`${metrics.impressions} ${metrics.impressions === 1 ? 'impression' : 'impressions'}`);
        if (metrics?.likes) metricsArray.push(`${metrics.likes} ${metrics.likes === 1 ? 'like' : 'likes'}`);
        if (metrics?.comments) metricsArray.push(`${metrics.comments} ${metrics.comments === 1 ? 'reply' : 'replies'}`);
        if (metrics?.shares) metricsArray.push(`${metrics.shares} ${metrics.shares === 1 ? 'retweet' : 'retweets'}`);
        if (metrics?.quote) metricsArray.push(`${metrics.quote} ${metrics.quote === 1 ? 'quote tweet' : 'quote tweets'}`);
        if (metricsArray.length === 0) {
            metricsArray.push("engagement pending");
        }
    
        return metricsArray.join(', ');
    };

    const WeeklyStats = ({
        title,
        totalInterventions,
        totalEngagement,
        totalUsers,
        rank,
        bestIntervention,
        headerTitle,
        data,
        showLeaderBoard
    }) => {

        return (
            <ColumnContainer>
                {headerTitle && (
                    <HeaderContent>
                        <Header title={headerTitle} />
                    </HeaderContent>
                )}
                <Wrapper
                    style={{
                        backgroundColor: '#fff',
                        flexDirection: 'column',
                        display: 'flex',
                        paddingLeft: '20px',
                        paddingRight: '0px',
                        alignItems: 'stretch',
                        flex: 1,
                    }}
                >
                    {isLoading ? (
                        <div style={{ marginBottom: '100px' }}>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    flexDirection: isMobile ? 'column' : 'row',
                                    display: 'flex',
                                    alignContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: '42%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        flex: 1,
                                    }}
                                >
                                    <Title
                                        style={{
                                            fontSize: '25px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {title}
                                    </Title>
        
                                    <div
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            gap: '20px',
                                            marginRight: isMobile ? '20px' : '0px',
                                        }}
                                    >
                                        <Wrapper
                                            style={{
                                                flexDirection: 'column',
                                                display: 'flex',
                                                padding: '10px',
                                                textAlign: 'center',
                                                margin: '0px',
                                                flex: 1,
                                            }}
                                        >
                                            Total Interventions
                                            <strong
                                                style={{
                                                    fontSize: '22px',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {totalInterventions}
                                            </strong>
                                        </Wrapper>

                                        <Wrapper
                                            style={{
                                                flexDirection: 'column',
                                                display: 'flex',
                                                padding: '10px',
                                                textAlign: 'center',
                                                margin: '0px',
                                                flex: 1,
                                            }}
                                        >
                                            Total Engagement
                                            <strong
                                                style={{
                                                    fontSize: '22px',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {totalEngagement}
                                            </strong>
                                        </Wrapper>
                                    </div>

                                    <Wrapper
                                        style={{
                                            padding: '15px',
                                            textAlign: 'center',
                                            margin: '20px 0px',
                                            flex: 1,
                                            alignContent: 'center',
                                            justifyItems: 'center',
                                            marginRight: isMobile ? '20px' : '0px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                            }}
                                        >
                                            <p style={{ textAlign: 'right' }}>
                                                Average Engagement<br />
                                                Score (per post):
                                            </p>
                                            <strong
                                                style={{
                                                    marginLeft: '12px',
                                                    fontSize: '22px',
                                                    marginTop: '18px',
                                                }}
                                            >
                                                {totalInterventions > 0
                                                    ? `${(
                                                        totalEngagement /
                                                        totalInterventions
                                                    ).toFixed(1)}`
                                                    : '0.0'}
                                            </strong>
                                        </div>
                                        {rank && (
                                            <p>
                                                Ranking:
                                                <strong
                                                    style={{
                                                        marginLeft: '15px',
                                                        fontSize: '22px',
                                                    }}
                                                >
                                                    {`${rank}`}/
                                                    {totalUsers}
                                                </strong>
                                            </p>
                                        )}
                                    </Wrapper>
                                    {showLeaderBoard &&
                                        <BestInterventionComponent
                                            bestIntervention={bestIntervention}
                                            showLeaderBoard={true}
                                        />}
                                </div>
        
                                {showLeaderBoard ? (
                                    <LeaderBoard
                                        ranking={rankingUsers}
                                        fromMyImpact={true}
                                    />
                                ) : (
                                    <BestInterventionComponent
                                        bestIntervention={bestIntervention}
                                    />
                                )}
                            </div>
                            <ImpactChart
                                allUsers={headerTitle === 'Our Impact'}
                                userId={user._id}
                                data={data}
                                showLeaderBoard={showLeaderBoard}
                            />
                        </>
                    )}
                </Wrapper>
            </ColumnContainer>
        );
    };

    return (
        <>
            <Menu />
            <Container>
                <RowContainer>
                    <WeeklyStats
                        title="Last Week:"
                        totalInterventions={interventionsData?.lastWeek?.totalLastWeekUserId}
                        totalEngagement={engagement?.user?.lastWeek?.totalEngagement}
                        rank={rankingInfo.lastWeekRank}
                        totalUsers={rankingInfo.totalUsersLastWeek}
                        bestIntervention={bestInterventions.user?.lastWeek}
                        headerTitle="My Impact"
                        data={allUsersInterventions}
                    />

                    {isMobile
                        ? <WeeklyStats
                            title="All Time:"
                            totalInterventions={interventionsData.allTime?.totalAllTimeUserId}
                            totalEngagement={engagement?.user?.allTime?.totalEngagement}
                            rank={rankingInfo.allTimeRank}
                            totalUsers={rankingInfo.totalUsersAllTime}
                            bestIntervention={bestInterventions.user?.allTime}
                            data={userIdInterventions}
                        />
                        : <WeeklyStats
                            title="Last Week:"
                            totalInterventions={interventionsData.lastWeek?.totalLastWeekAllUsers}
                            totalEngagement={engagement?.allUsers?.lastWeek?.totalEngagement}
                            bestIntervention={bestInterventions.allUsers?.lastWeek}
                            headerTitle="Our Impact"
                            data={allUsersInterventions}
                        />}
                </RowContainer>
                <RowContainer>
                    {isMobile
                        ? <WeeklyStats
                            title="Last Week:"
                            totalInterventions={interventionsData.lastWeek?.totalLastWeekAllUsers}
                            totalEngagement={engagement?.allUsers?.lastWeek?.totalEngagement}
                            bestIntervention={bestInterventions.allUsers?.lastWeek}
                            headerTitle="Our Impact"
                            data={allUsersInterventions}
                        />
                        : <WeeklyStats
                            title="All Time:"
                            totalInterventions={interventionsData.allTime?.totalAllTimeUserId}
                            totalEngagement={engagement?.user?.allTime?.totalEngagement}
                            rank={rankingInfo.allTimeRank}
                            totalUsers={rankingInfo.totalUsersAllTime}
                            bestIntervention={bestInterventions.user?.allTime}
                            data={userIdInterventions}
                        />}

                    <WeeklyStats
                        title="All Time:"
                        totalInterventions={interventionsData.allTime?.totalAllTimeAllUsers}
                        totalEngagement={engagement?.allUsers?.allTime?.totalEngagement}
                        bestIntervention={bestInterventions.allUsers?.allTime}
                        showLeaderBoard={true}
                    />
                </RowContainer>
            </Container>
        </>
    );
};

export default MyImpact;
