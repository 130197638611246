import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { FavoriteBorderOutlined, Favorite } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import axios from 'axios';
import { apiUrl } from '../utils/common';
import useVerifyTwitterUrl from '../utils/useVerifyTwitterUrl'

const Wrapper = styled(({ userOption, ...rest }) => <div {...rest} />)`
    background: #FFF;
    border-radius: 10px;
    box-sizing: border-box;
    padding: ${({ userOption }) => (userOption ? '0px 12px 18px 12px' : '0px 0px 18px 0px')};
    max-height: ${({ userOption }) => (userOption === 'me' ? '800px' : '500px')};
    overflow-y: auto;
`;

const Title = styled.h2`
    color: #3498db;
`;

const InterventionsList = styled.div`
    margin-top: 10px;
`;

const MailRow = styled(({ boosted, userOption, ...rest }) => <div {...rest} />)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    font-weight: ${({ boosted, userOption }) => (boosted || userOption === 'me'? 'normal' : 'bold')};  
    &:hover {
        background-color: #f1f1f1;
    }
`;

const AlternativeText = styled.div`
    text-align: left;
    color: #555;
    margin-right: 25px;
    margin-top: 3px;
    line-height: 20px
`;

const RowContent = styled.div`
    flex: 5;
    text-align: left;    
`;

const ActionsContainer = styled(({ isMobile, ...rest }) => <div {...rest} />)`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 5px;
    flex-direction: ${({ isMobile }) => (isMobile? 'column' : 'row')}; 
`;

const NoDataContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 90px 0px;
`;

const InterventionsComponent = ({interventionsList, userOption, userMainTwitterAccount, boostedListProp, boostedListUpdate, userTwitterId, repliesList=null, handleReply, tweet, errorLiking}) => {
    const { getTweetIdFromTwitterUrl } = useVerifyTwitterUrl();
    const [boostedList, setBoostedList] = useState(boostedListProp);
    let interventions = interventionsList;

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        setBoostedList(boostedListProp);
    }, [boostedListProp]);

    const handleOpenIntervention = async (url) => {   
        window.open(url, '_blank');
    };

    const handleLikeTweet = async ( twitterPostId ) => {   
        try {
            const response = await axios.post(`${apiUrl}/boostCounterSpeak/likeTweet`, { userMainTwitterAccount, twitterPostId, userTwitterId });
            if (response.data) {
                setBoostedList(response.data);
                boostedListUpdate(response.data);
            }
            else {
                errorLiking();
            }
        } catch (error) {
            console.error('Error en handleLikeTweet:', error);
        }
    };

    const isTweetLiked = (twitterPostId) => {
        const boostedItem = boostedList?.find(item => item?.twitterPostId === twitterPostId);
        return boostedItem?.liked || false;
    };

    const isTweetBoosted = (twitterPostId) => {
        const boostedItem = boostedList?.find(item => item?.twitterPostId === twitterPostId);
        return boostedItem? true : false;
    };

    return (
        <>
            <Wrapper userOption={userOption}>
                <Title>{!userOption ? 'Reply To Tweet' : userOption === 'me' ? 'My Recent Interventions' : 'Recent Team Interventions'}</Title>

                {interventions?.length > 0 ? (
                    <InterventionsList>
                        {interventions?.map((intervention, index) => {
                            const metrics = intervention?.monitorData?.metrics?.intervention || {};
                            const engagementScore = intervention?.monitorData?.metrics?.engagement || null;
                            const metricsArray = [];

                            if (metrics?.impressions) metricsArray.push(`${metrics.impressions} impression${metrics.impressions > 1 ? 's' : ''}`);
                            if (metrics?.likes) metricsArray.push(`${metrics.likes} like${metrics.likes > 1 ? 's' : ''}`);
                            if (metrics?.comments) metricsArray.push(`${metrics.comments} repl${metrics.comments > 1 ? 'ies' : 'y'}`);
                            if (metrics?.shares) metricsArray.push(`${metrics.shares} retweet${metrics.shares > 1 ? 's' : ''}`);
                            if (metrics?.quote) metricsArray.push(`${metrics.quote} quote tweet${metrics.quote > 1 ? 's' : ''}`);
                            if (engagementScore) metricsArray.push(`engagement score: ${engagementScore}`);
                            if (metricsArray?.length === 0) {
                                metricsArray.push('engagement pending');
                            }

                            const interventionText = intervention?.interventionText || intervention.text || '';
                            const cleanedReplyText = typeof interventionText === 'string' 
                                ? interventionText.replace(/^(@\w+\s)+/, '') 
                                : interventionText;
                            const match = interventionText.match(/^@(\w+)/);
                            const replyTo = match ? match[0] : '';
                            const twitterPostId = intervention.id || getTweetIdFromTwitterUrl(intervention.responseUrl);
                            const isLiked = isTweetLiked(twitterPostId);
                            const isBoosted = isTweetBoosted(twitterPostId);
                            let replyUrl; 
                            if (!userOption){
                                replyUrl = `https://twitter.com/${intervention.author?.username}/status/${intervention.id}`;
                            }

                            const repliesForIntervention = repliesList?.filter(reply =>
                                reply?.referenced_tweets?.[0]?.id === twitterPostId
                            ) || [];

                            return (
                                <div key={index}>
                                    <MailRow
                                        boosted={isBoosted}
                                        userOption={userOption}
                                        onClick={() => handleOpenIntervention(intervention.responseUrl || replyUrl)}
                                    >
                                        <AlternativeText style={{ width: '60px', fontSize:'12px'}}>
                                            {new Date(intervention?.createdAt || intervention.created_at).toLocaleDateString()}
                                        </AlternativeText>
                                        <RowContent>
                                            "{cleanedReplyText}"
                                            <AlternativeText>
                                                {intervention?.userData?.mainTwitterAccount || intervention?.userData?.username || intervention.author.username} in reply to{' '}
                                                {intervention?.toxicUserDetails?.username || replyTo} <br />
                                                {userOption && metricsArray.join(', ')}
                                            </AlternativeText>
                                        </RowContent>
                                        {userOption === 'all' && (
                                            <ActionsContainer isMobile={isMobile}>
                                                <IconButton onClick={(e) => { e.stopPropagation(); handleLikeTweet(twitterPostId); }}>
                                                    {isLiked ? (
                                                        <Favorite style={{ color: '#3498db' }} />
                                                    ) : (
                                                        <FavoriteBorderOutlined />
                                                    )}
                                                </IconButton>
                                                <Button variant="contained" size="small" onClick={(e) => { e.stopPropagation(); handleReply(intervention, isLiked );}}>
                                                    Reply
                                                </Button>
                                            </ActionsContainer>
                                        )}
                                    </MailRow>

                                    {repliesForIntervention?.length > 0 && (
                                        <div>
                                            {repliesForIntervention.map((reply, idx) => {
                                                const replyTweetId = reply?.id;
                                                const isReplyLiked = isTweetLiked(replyTweetId);
                                                const cleanedReplyText = reply?.text?.replace(/^(@\w+\s)+/, '') || reply?.text;
                                                const isReplyBoosted = isTweetBoosted(replyTweetId);
                                                const replyUrl = `https://twitter.com/${reply?.author_details?.username}/status/${replyTweetId}`;

                                                return (
                                                    <MailRow
                                                        key={reply?.id || idx}
                                                        boosted={isReplyBoosted}
                                                        onClick={() => handleOpenIntervention(replyUrl)}>
                                                        <AlternativeText style={{ width: '60px' }}>
                                                        </AlternativeText>
                                                        <RowContent>
                                                            "{cleanedReplyText}"
                                                            <AlternativeText>
                                                                {reply?.author_details?.username} in reply to {intervention?.userData?.mainTwitterAccount}
                                                            </AlternativeText>
                                                        </RowContent>
                                                        {userOption && (
                                                        <ActionsContainer isMobile={isMobile}>
                                                            <IconButton onClick={(e) => { e.stopPropagation(); handleLikeTweet(replyTweetId);}}>
                                                                {isReplyLiked ? (
                                                                    <Favorite style={{ color: '#3498db' }} />
                                                                ) : (
                                                                    <FavoriteBorderOutlined />
                                                                )}
                                                            </IconButton>
                                                            <Button variant="contained" size="small" onClick={(e) => { e.stopPropagation(); handleReply(intervention, isReplyLiked, reply); }}>
                                                                Reply
                                                            </Button>
                                                        </ActionsContainer>
                                                          )}
                                                    </MailRow>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </InterventionsList>
                ) : (
                    <NoDataContainer>
                        <span>No data in the last 3 days</span>
                    </NoDataContainer>
                )}

            </Wrapper>
        </>
    );
};

export default InterventionsComponent;