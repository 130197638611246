import styled from 'styled-components';
import Menu from '../components/Menu';
import LeaderBoard from '../components/LeaderBoard';
import PieChartComponent from '../components/PieChart';
import HorizontalChart from '../components/HorizontalChart';
import VerticalChart from '../components/VerticalChart';
import React, { useEffect, useState } from 'react';
import { apiUrl } from '../utils/common';
import axios from 'axios';
import Selector from '../components/Selector';
import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Spinner from '../components/Spinner';

const Container = styled.div`
    background: #f2f2f2;
    width: 100%;
    height: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
`;
const ColWrapper = styled.div`
    box-sizing: border-box;
    padding: 30px 10px;
    width: 36%;
    &:last-child {
        width: 28%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ChartWrapper = styled.div`
    background: white;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    margin: 0 0 20px 0;
    min-height: 550px;
    padding: 20px 0;
    width: 100%;
`

const ExportButtonContainer = styled.div`
    float: right;
    margin: 15px 15px 0 0;

    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        float: none;
        justify-content: center;
        margin: 50px 0 0 0;
    }
`;

const csvConfigInterventions = mkConfig({
    filename: `Interventions - ${dayjs().format('h:mmA - MMM D, YYYY')}`,
    fieldSeparator: ',',
    useKeysAsHeaders: true,
});

const Interventions = () => {
    const [ranking, setRanking] = useState();
    const [strategiesEmployed, setStrategiesEmployed] = useState();
    const [strategiesByDay, setStrategiesByDay] = useState();
    const [selectedPeriod, setSelectedPeriod] = useState('last week');
    const [usersEngagement, setUsersEngagement] = useState([]);
    const [strategiesEngagement, setStrategiesEngagement] = useState([]);
    const [toxicUsersEngagement, setToxicUsersEngagement] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRanking();
        getStrategiesEmployed();
        getStrategiesByDay();
        getToxicUserEngagement();
        getStrategyEngagement();
        getUserEngagement();
    }, [selectedPeriod]);

    const getRanking = async () => {
        try {
            const res = await axios.get(`${apiUrl}/metrics/getRankingUsers`, {
                params: {
                    selectedPeriod: selectedPeriod,
                }
            });
            setRanking(res.data);
            
        } catch (err) {
            console.error('Failed fetching Ranking: ', err);
        }
    };

    const getStrategiesEmployed = async () => {
        try {
            const response = await axios.get(`${apiUrl}/metrics/getStrategiesEmployed`, {
                params: {
                    selectedPeriod: selectedPeriod,
                }
            });
            setStrategiesEmployed(response.data)
        } catch (err) {
            console.error('Failed fetching Strategies: ', err);
        }
    };

    const getStrategiesByDay = async () => {
        try {
            const res = await axios.get(`${apiUrl}/metrics/getStrategiesByDay`, {
                params: {
                    selectedPeriod: selectedPeriod,
                }
            });
            setStrategiesByDay(res.data)
        } catch (err) {
            console.error('Failed fetching Strategies: ', err);
        }
    };

    const getStrategyEngagement = async () => {
        try {
            const res = await axios.get(`${apiUrl}/metrics/getStrategyEngagement`, {
                params: {
                    selectedPeriod: selectedPeriod,
                }
            });
            setStrategiesEngagement(res.data)
        } catch (err) {
            console.error('Failed to fetch the engagament based on strategies: ', err)
        }
    }

    const getUserEngagement = async () => {
        try {
            const res = await axios.get(`${apiUrl}/metrics/getUserEngagement`, {
                params: {
                    selectedPeriod: selectedPeriod,
                }
            });
            setUsersEngagement(res.data);
        } catch (err) {
            console.error('Failed to fetch the engagament based on users: ', err)
        }
    }

    const getToxicUserEngagement = async () => {
        try {
            const res = await axios.get(`${apiUrl}/metrics/getToxicUserEngagement`, {
                params: {
                    selectedPeriod: selectedPeriod,
                }
            });
            setToxicUsersEngagement(res.data)
        } catch (err) {
            console.error('Failed to fetch the engagament based on toxic users: ', err)
        }
    }

    const handleSelectedPeriodChange = (value) => {
        setSelectedPeriod(value);
    };

    const handleExportData = async () => {
        setLoading(true);
        try {
            let res = await axios.get(`${apiUrl}/interventions/getInterventions`, {
                params: {
                    selectedPeriod: selectedPeriod
                }
            })

            const interventions = res.data.map(item => {
                const metrics = item.monitorData?.metrics || {};
                const tweetUserLink = `=HYPERLINK("https://x.com/${item.toxicUserDetails?.username}", "${item.toxicUserDetails?.username}")`;
                const tweetDateLink = `=HYPERLINK("https://x.com/${item.toxicUserDetails?.username}/status/${item.postDetails?.twitterPostId}", "${dayjs(item.postDetails?.twitterCreatedAt).format('h:mmA - MMM D, YYYY')}")`;
                const detoxifierUserLink = `=HYPERLINK("https://x.com/${item.userData?.mainTwitterAccount}", "${item.userData?.mainTwitterAccount}")`;
                const interventionDateLink = item.createdAt ? `=HYPERLINK("${item.responseUrl}", "${dayjs(item.createdAt).format('h:mmA - MMM D, YYYY')}")` : '';
                
                return {
                    'User': tweetUserLink,
                    'Tweet Text': item.postDetails?.content,
                    'Tweet Date': tweetDateLink,
                    'Toxicity Score': item.postDetails?.toxicScore,
                    'Toxicity Perspective Score': item.postDetails?.toxicPerspectiveScore,
                    'X user': detoxifierUserLink,
                    'SMD Email': item.userData?.email,
                    'Suggested Text': item.appliedResponse[0]?.text,
                    'Final Text': item.interventionText,
                    'Intervention Date': interventionDateLink,
                    'Strategy': item.appliedStrategy[0]?.name,
                    'Style': item.appliedStyle[0]?.name? item.appliedStyle[0]?.name : 'Personalized',
                    'Used Prompt': item.appliedResponse[0]?.prompt? item.appliedResponse[0]?.prompt : '',
                    'Intervention Bookmark': metrics.intervention?.bookmark,
                    'Intervention Comments': metrics.intervention?.comments,
                    'Intervention Impressions': metrics.intervention?.impressions,
                    'Intervention Likes': metrics.intervention?.likes,
                    'Intervention Quote': metrics.intervention?.quote,
                    'Intervention Shares': metrics.intervention?.shares,
                    'Post Bookmark': metrics.post?.bookmark,
                    'Post Comments': metrics.post?.comments,
                    'Post Impressions': metrics.post?.impressions,
                    'Post Likes': metrics.post?.likes,
                    'Post Quote': metrics.post?.quote,
                    'Post Shares': metrics.post?.shares,
                    'Post Partisanship': item.toxicUserDetails?.partisanship,
                    'Responder Partisanship': item.userData?.partisanship,
                    "i_media_url": item.postUrls ? item.postUrls.join('; ') : '',
                    "i_media_embedded": item.animatedGif ? item.gifUrl : '',
                    "Post Timestamp": item.postDetails?.createdAt,
                    "Intervention Timestamp": item.createdAt,
                }
            });
            
            if (interventions.length) {
                const csvInterventions = generateCsv(csvConfigInterventions)(interventions);
                download(csvConfigInterventions)(csvInterventions, 'interventions');
            }
        } catch (err) {
            console.error('Failed to create csv of interventions.', err)
        }
        setLoading(false);
    }

    return (
        <>
            <Menu />
            <ExportButtonContainer>
                {!loading ?
                    <Button variant="contained" onClick={handleExportData} startIcon={<FileDownload />}>
                        Export
                    </Button>
                    :
                    <Spinner />
                }
            </ExportButtonContainer>
            <Selector optionChange={handleSelectedPeriodChange} value={selectedPeriod}/>
            <Container>
                <ColWrapper>
                    <ChartWrapper>
                        <HorizontalChart data={strategiesByDay} />
                    </ChartWrapper>
                    <ChartWrapper>
                        <PieChartComponent data={strategiesEmployed} />
                    </ChartWrapper>
                </ColWrapper>
                <ColWrapper>
                    <ChartWrapper>
                        <VerticalChart title={'Impact by Strategy'} data={strategiesEngagement} />
                    </ChartWrapper>
                    <ChartWrapper>
                        <VerticalChart title={'Impact by SMD User'} data={usersEngagement} />
                    </ChartWrapper>
                    <ChartWrapper>
                        <VerticalChart title={'Impact by Toxic User'} data={toxicUsersEngagement} />
                    </ChartWrapper>
                </ColWrapper>
                <ColWrapper>
                    <ChartWrapper>
                        <LeaderBoard ranking={ranking}/>
                    </ChartWrapper>
                </ColWrapper>
            </Container>
        </>
    );
};

export default Interventions;
